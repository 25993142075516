// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-project-js": () => import("../src/pages/project.js" /* webpackChunkName: "component---src-pages-project-js" */),
  "component---src-pages-innovation-js": () => import("../src/pages/innovation.js" /* webpackChunkName: "component---src-pages-innovation-js" */),
  "component---src-pages-team-js": () => import("../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-societa-cooperativa-js": () => import("../src/pages/societaCooperativa.js" /* webpackChunkName: "component---src-pages-societa-cooperativa-js" */),
  "component---src-pages-moncaro-js": () => import("../src/pages/moncaro.js" /* webpackChunkName: "component---src-pages-moncaro-js" */),
  "component---src-pages-belisario-js": () => import("../src/pages/belisario.js" /* webpackChunkName: "component---src-pages-belisario-js" */),
  "component---src-pages-univpm-js": () => import("../src/pages/univpm.js" /* webpackChunkName: "component---src-pages-univpm-js" */),
  "component---src-pages-cia-js": () => import("../src/pages/cia.js" /* webpackChunkName: "component---src-pages-cia-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-privacy-policy-js": () => import("../src/pages/privacyPolicy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-assam-js": () => import("../src/pages/assam.js" /* webpackChunkName: "component---src-pages-assam-js" */)
}

